import { Container, Grid } from '@material-ui/core'
import React from 'react'
import Slider from 'react-slick';
import Layout from '../../components/layout'
import GridRow from '../../components/_grid-row'


import pageStyles from '../../assets/scss/class-internal.module.scss'

import sportsImg from '../../images/train/sports.jpg';

export default function SportsPerformanceTraining() {
    const sliderImages = [
        sportsImg
    ];

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3.6,
        slidesToScroll: 3.6,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.05,
                    slidesToScroll: 2.05,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.02,
                    slidesToScroll: 1.02,
                    infinite: false,
                    dots: true
                }
            },
        ]
    };

    return (
        <React.Fragment>
            <Layout pageName="Specialized Training" backLink="/specialized-training">
                <div className={["class-internal", pageStyles.wrapper].join(" ")}>
                    <Container maxWidth="xl">
                        <GridRow>
                            {/* Slider section */}
                            <Grid container spacing={3} justify="center">
                                <Grid item xs={12}>
                                    {sliderImages?
                                    <Slider  {...settings}>
                                        {
                                            sliderImages.map(img => {
                                                return (
                                                    <div>
                                                        <img src={img}/>
                                                    </div>
                                                )
                                            })
                                        }

                                    </Slider>
                                    :
                                    ""

                                    }
                                </Grid>
                                {/* Content section */}
                                <Grid item xs={12} lg={8}>
                                    <div className={pageStyles.header}>
                                        <h2 className="h2">Sports Performance Training</h2>
                                        <h3 className={pageStyles.subtitle}>TRAIN TO COMPETE</h3>
                                    </div>
                                    <div className={pageStyles.desc}>
                                        <p>
                                            For those training to excel and compete, we suggest our SPT program which focuses on movement progression, and sports-specific techniques designed particularly around your training and competition needs. This program would be applicable for those seeking to accomplish a:
                                            <br />
                                            marathon (5km, 10km, 21.1km, 42.2km)<br />
                                            ultramarathon (50km + distances)<br />
                                            triathlon (super sprint, sprint, Olympic, half ironman, full ironman)<br />
                                            <br /><br />
                                            This program is also applicable for individual and team sport players ranging from soccer, basketball, all racquetball sports, gymnastics, track and field, and more. 
                                            <br /><br />
                                            To inquire or book, please send an email to info@beachmonkeyz.com.
                                        </p>
                                    </div>
                                    <Grid item xs={12} md={6} lg={5}>
                                        <div className={pageStyles.reserveButton}>
                                            <a href="mailto:info@beachmonkeyz.com" className={pageStyles.emailLink}>
                                                Send us an e-mail
                                            </a>
                                        </div>
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </GridRow>
                    </Container>
                </div>
            </Layout>
        </React.Fragment>
    )
}
